.language-selector
  margin-left: $spacer * 4
  font-size: 0.8rem
  color: $black

  @media screen and (max-width: 840px)
    margin-left: 0
    margin-top: $spacer

  a
    padding: calc($spacer / 2) 0
    color: inherit
