.section
  display: flex
  min-height: 100vh
  padding: $spacer * 2
  flex-direction: column
  position: relative

  @media screen and (max-width: 600px)
    padding: $spacer * 1.5

  @media screen and (max-width: 550px)
    padding: $spacer

  &::after
    content: ''
    height: 100%
    width: 100%
    position: absolute
    left: 0
    top: 0
    z-index: -1
    background-color: $red

  &:nth-child(odd)
    &::after
      clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 100vh)

      @media screen and (max-width: 1250px)
        clip-path: polygon(0 0, 100% 0, 100% 20vh, 0 90vh)

      @media screen and (max-width: 950px)
        clip-path: polygon(0 0, 100% 0, 100% 30vh, 0 90vh)

      @media screen and (max-width: 840px)
        clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 80vh)

      @media screen and (max-width: 500px)
        clip-path: polygon(0 0, 100% 0, 100% 50vh, 0 75vh)

    &:not(.hero-section) .container
      margin-top: 320px

    .section__header
      align-self: flex-start

    .section__content
      align-self: flex-end

  &:nth-child(even)
    justify-content: flex-end

    &::after
      clip-path: polygon(0 80vh, 0 100vh, 100% 100vh, 100% 0)

      @media screen and (max-width: 1250px)
        clip-path: polygon(0 80vh, 0 100vh, 100% 100vh, 100% 10vh)

      @media screen and (max-width: 950px)
        clip-path: polygon(0 70vh, 0 100vh, 100% 100vh, 100% 10vh)

      @media screen and (max-width: 840px)
        clip-path: polygon(0 60vh, 0 100vh, 100% 100vh, 100% 20vh)

      @media screen and (max-width: 500px)
        clip-path: polygon(0 50vh, 0 100vh, 100% 100vh, 100% 25vh)

    &.page-content-section
      justify-content: center

    .section__header
      align-self: flex-end

    .section__content
      align-self: flex-start

  &.page-content-section
    &::after
      clip-path: polygon(0 calc(100% - 250px), 0 100%, 100% 100%, 100% calc(100% - 250px))

.section__header
  margin-bottom: $spacer * 2
  color: $white
