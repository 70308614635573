.blockquote
    padding: $spacer
    border-left: calc($spacer / 4) solid $red
    box-shadow: $shadow
    border-radius: calc($spacer / 4)

.blockquote__source
    display: block
    margin-top: calc($spacer / 2)
    text-align: right
