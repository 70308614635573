.card
  min-width: 50%
  max-width: 60%
  min-height: 350px
  padding: $spacer * 2
  background-color: $white
  color: $black
  border-radius: calc($spacer / 4)
  box-shadow: $shadow--elevated

  @media screen and (max-width: 1600px)
    min-width: 60%
    max-width: 75%

  @media screen and (max-width: 1024px)
    min-width: 70%
    max-width: 90%

  @media screen and (max-width: 600px)
    min-width: 75%
    max-width: none
    padding: $spacer * 1.5

  @media screen and (max-width: 550px)
    padding: $spacer

  &.content-flow h2, h3, h4, h5, h6
    text-shadow: none

.card--large
  max-width: 75%

  @media screen and (max-width: 1024px)
    max-width: 90%

  @media screen and (max-width: 800px)
    max-width: 95%

  @media screen and (max-width: 600px)
    max-width: none

.card--full-width
  max-width: none
  width: 100%

.card__heading
  text-shadow: none
  margin-bottom: $spacer

  &:last-child
    margin-bottom: 0

.card__btn
  margin-top: $spacer * 2

.card__text
  margin-bottom: $spacer * 2

  &:last-child
    margin-bottom: 0

.card__list
  margin-bottom: $spacer * 2

  &:last-child
    margin-bottom: 0

  li
    margin-left: $spacer * 2
    margin-bottom: $spacer

    &:last-child
      margin-bottom: 0
