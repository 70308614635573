.btn
  display: inline-block
  padding: calc($spacer / 2) $spacer
  background-color: $black
  color: $white
  border-radius: calc($spacer / 4)
  box-shadow: $shadow
  text-transform: uppercase
  font:
    weight: $main-font--medium
    size: 0.9rem
  letter-spacing: 1.25px
  cursor: pointer
  transition: $transition

  &:hover, &:focus-visible
    box-shadow: $shadow--elevated
    transform: translateY(-1px)
