.legal-nav
  ul
    display: flex

  li
    margin-right: $spacer * 2

    &:last-child
      margin-right: 0

  a
    display: inline-block
    padding: calc($spacer / 2) 0
    font:
      size: 0.8rem
    color: $black

    &::after
      content: ''
      display: block
      height: 1px
      width: 0
      background-color: $black
      opacity: 0
      transition: inherit

    &:hover::after
      width: 100%
      opacity: 1
