*
  margin: 0
  padding: 0
  box-sizing: border-box

html, body
  height: 100%
  font:
    size: 20px
    family: $main-font
  line-height: 1.4
  color: $black

  @media screen and (max-width: 400px)
    font-size: 18px

  @media screen and (max-width: 360px)
    font-size: 16px

  @media screen and (max-width: 320px)
    font-size: 15px

img
  display: block
  max-width: 100%
  height: auto

a
  text-decoration: none
  transition: $transition
  color: inherit

nav ul
  list-style: none

small
  font-size: inherit

strong
  font-weight: inherit

address
  font-style: inherit
