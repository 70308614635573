@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 300
  font-display: swap
  src: local('Roboto Light'), local('Roboto-Light'), url('/assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), url('/assets/fonts/roboto-v20-latin-300.woff') format('woff')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: local('Roboto'), local('Roboto-Regular'), url('/assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), url('/assets/fonts/roboto-v20-latin-regular.woff') format('woff')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 500
  font-display: swap
  src: local('Roboto Medium'), local('Roboto-Medium'), url('/assets/fonts/roboto-v20-latin-500.woff2') format('woff2'), url('/assets/fonts/roboto-v20-latin-500.woff') format('woff')
